/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { get as g } from "lodash";

import { connect } from "react-redux";

import { be } from "../../../../utils/bliss";

import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

import WizardDuck from "../../../../redux/ducks/wizard";

const MODULE_NAME = "ProjectDetailScreen";

const EditableState = ({ project, currentProject, dispatch, titleInside, handleFinancingCallback, flippingMode, flipped, onSetHeight }) => {
  // only the necessary occurences of "project" in this component are replaced by "currentProject", ideally all should be replaced
  const handleFinancing = async (funding = "") => {
    await dispatch(
      WizardDuck.patchProject(
        project.id,
        {
          funding,
          name: project.name
        },
        true,
        titleInside
      )
    );
    window.alertify.success(
      `Projekt je nyní financován ${funding === "crowd" ? "formou příspěvků a předprodejem" : "vámi"}.`
    );

    if ("function" === typeof handleFinancingCallback) {
      handleFinancingCallback();
    }
  };

  const headingDivStyle = {};
  const rowStyle = {};
  if (titleInside) {
    headingDivStyle.display = "none";
    rowStyle.display = "block";
    if (flippingMode) {
      rowStyle.position = "absolute";
      if (flipped) {
        rowStyle.transform = "perspective(1000px) rotateY(-180deg)";
      } else {
        rowStyle.transform = "perspective(1000px) rotateY(0deg)";
      }
    }
  }

  return (
    <div
      className={`row${flippingMode ? " costflippingModeTransition" : ""}`}
      style={rowStyle}
      ref={ref => {
        if (ref) {
          onSetHeight && onSetHeight(ref);
        }
      }}
    >
      <div className="col">
        <div className={be(MODULE_NAME, "spacing", "xl")} style={headingDivStyle} data-titleInside={titleInside} />
        {!titleInside && (
          <Headline bold pageSub>
            Jak chcete vydání knihy financovat?
          </Headline>
        )}
        <div className="row">
          <div className="col-12">
            <div className={be(MODULE_NAME, "financing")}>
              {titleInside && (
                <div style={{ textAlign: "center", paddingTop: "25px", paddingBottom: "25px" }}>
                  <div className={be(MODULE_NAME, "first")}>
                    <Headline bold xs>
                      Jak chcete vydání knihy financovat?
                    </Headline>
                  </div>
                </div>
              )}
              <div
                className={be(
                  MODULE_NAME,
                  "financingItem",
                  `${!titleInside ? "first " : ""}${g(currentProject || project, "funding", "") === "crowd" ? "active" : ""}`
                )}
                onClick={() => handleFinancing("crowd")}
              >
                <Headline bold subheadline>
                  Předprodejem
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <Text>Nechte si knihu financovat od čtenářů formou příspěvků a předprodejem.</Text>
              </div>
              <div
                className={be(MODULE_NAME, "financingItem", `${g(currentProject || project, "funding", "") === "own" ? "active" : ""}`)}
                onClick={() => handleFinancing("own")}
              >
                <Headline bold subheadline>
                  Vlastními financemi
                </Headline>
                <div className={be(MODULE_NAME, "spacing", "xs")} />
                <div>
                  <Text>
                    Pro tento způsob financování nás prosím kontaktujte na{" "}
                    <a href="mailto:dikyvam@pointa.cz" onClick={e => e.stopPropagation()}>
                      dikyvam@pointa.cz
                    </a>{" "}
                    a uveďte název vašeho projektu.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const project = WizardDuck.getDetail(state);
  return {
    currentProject: project,
  };
};

export default connect(mapStateToProps)(EditableState);
