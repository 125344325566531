import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, isEmpty, uniqBy } from "lodash";

import { be, bm } from "../../utils/bliss";
import "./projectCosts.css";

import EnumsDuck from "../../redux/ducks/enums";
import { formatPrice, isMobile } from "../../utils/utils";
import Headline from "../headline/headline";
import Text from "../text/text";
import Info from "../info/info";
import settingsService from "../../services/settingsService";
import EditableState from "../../pages/details/project/components/editableState";
import WizardDuck from "../../redux/ducks/wizard";

const MODULE_NAME = "ProjectCosts";

const SHOW_COMMISSIONS_SEPARATELY = true;
const ALWAYS_SHOW_ZERO_COSTS = false && process.env.REACT_APP_ENV === "development";

class ProjectCosts extends Component {
  constructor(props) {
    super(props);

    const showColleagues = !!g(props, "project.handshakes", []).find(
      hs => hs.state === g(props, "handshakeStates.accepted.name", "")
    );
    let modulesToShow = [];

    if (!isEmpty(g(props, "project.basePrice", {}))) {
      modulesToShow = [...modulesToShow, "basePrice", "others", "comissions"];
    }

    if (showColleagues) {
      modulesToShow.push("colleagues");
    }

    if (isMobile()) {
      modulesToShow = [];
    }

    this.state = {
      modulesToShow,
      deposit: null,
      showCalculator: !!(props.currentProject && props.currentProject.funding),
      panelHeight: null,
    };
  }

  componentDidMount() {
    settingsService.getDepositSettings().then(settings => this.setState({ deposit: settings }));
  }

  getColleagues(acceptedHandshakes = []) {
    const { data } = this.props;

    return uniqBy([...g(data, "required", []), ...g(data, "all", [])], "id").map(subrole => {
      const contract = acceptedHandshakes.find(hs => g(hs, "subrole.id", "") === subrole.id);
      const show = !!contract || !!g(data, "required", []).find(req => g(req, "id", "") === subrole.id);

      return show ? (
        <Fragment key={subrole.id}>
          <div className="row">
            <div className="col-sm">{subrole.name}</div>
            <div className="col-sm-auto">
              <Text small>
                {" "}
                {contract ? (
                  <Fragment>
                    <b>{formatPrice(contract.price)}</b> Kč
                  </Fragment>
                ) : (
                  "-"
                )}
              </Text>
            </div>
          </div>
        </Fragment>
      ) : null;
    });
  }

  getOtherCosts() {
    const { project, currentProject } = this.props;

    return (
      <Fragment>
        {(!SHOW_COMMISSIONS_SEPARATELY && (
          <div className="row">
            <div className="col-sm">
              <Info className="pr-2">
                Tým Pointy dělá všechno pro to, aby vaše vydavatelská zkušenost byla co nejpříjemnější. Pointa provize je
                odměna pro nás.
              </Info>
              Pointa provize
            </div>
            <div className="col-sm-auto">
              <Text small>
                <b>{formatPrice(g(project, "financing.entryFee", 0))}</b> Kč
              </Text>
            </div>
          </div>
        )) ||
          ""}
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Vaše knihy budou před cestou ke čtenářům uschovány v našem moderním, pojištěném a zabezpečeném skladu.
            </Info>
            Skladování
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.warehouseCosts", 0))}</b> Kč
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Zajistíme přípravu a distribuci elektronické verze knihy pro čtenáře, kteří preferují čtečku.
            </Info>
            Výroba a distribuce e-knihy
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(project, "financing.eBookCosts", 0))}</b> Kč
            </Text>
          </div>
        </div>
        {(((currentProject.financing && currentProject.financing.buffer) || ALWAYS_SHOW_ZERO_COSTS) && (
          <div className="row">
            <div className="col-sm">
              <Info className="pr-2">
                V kalkulačce počítáme s nejrůznějšími náklady, i přesto může nastat situace, kdy se dodatečně náklady
                změní, např. navýšením počtu stran knihy. Pro takové případy počítáme v rozpočtu s rezervou ve formě
                nepředpokládaných nákladů
              </Info>
              Nepředpokládané náklady
            </div>
            <div className="col-sm-auto">
              <Text small>
                <b>{formatPrice(g(currentProject, "financing.buffer", 0))}</b> Kč
              </Text>
            </div>
          </div>
        )) ||
          ""}
        {(((currentProject.financing && currentProject.financing.pgwFee) || ALWAYS_SHOW_ZERO_COSTS) && (
          <div className="row">
            <div className="col-sm">
              <Info className="pr-2">
                Díky předprodeji můžete získat peníze na vydání knihy. Nesmíme však zapomínat na poplatky za použití
                platební brány.
              </Info>
              Poplatky za platební bránu
            </div>
            <div className="col-sm-auto">
              <Text small>
                <b>{formatPrice(g(project, "financing.pgwFee", 0))}</b> Kč
              </Text>
            </div>
          </div>
        )) ||
          ""}
        {/* <div className="row"> */}
        {/* <div className='col-sm'> */}
        {/* <Info className="pr-2">I my bohužel musíme zaplatit daně za hmotné zboží a služby, bez kterých vaše kniha nevznikne. Tato částka tyto výdaje pokrývá.</Info> */}
        {/* Daně na služby */}
        {/* </div> */}
        {/* <div className='col-sm-auto'> */}
        {/* <Text small><b>{formatPrice(g(project, 'financing.vatSafety', 0))}</b> Kč</Text> */}
        {/* </div> */}
        {/* </div> */}
      </Fragment>
    );
  }

  getContributorCommission(contributorCosts) {
    const { currentProject } = this.props;
    return contributorCosts * (g(currentProject, "financing.%.contributorWorkCommission", 0) / 100);
  }

  getComissions(contributorCosts) {
    const { currentProject } = this.props;

    const contributorComission = this.getContributorCommission(contributorCosts);

    return (
      <Fragment>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              Na platformě najdete databázi více než 800 pečlivě prověřených knižních profesionálů pro spolupráci na
              vaší knize. Zajišťujeme smlouvy, vyplácení odměn a další administrativu. Povinní kolegové od nás po vydání
              knihy obdrží výtisk knihy.
            </Info>
            Provize z práce kolegů
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(contributorComission)}</b> Kč
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Info className="pr-2">
              {"own" === currentProject.funding
                ? "Zajišťujeme administrativu a dohled nad výrobou knihy, aby finální podoba splnila vaše očekávání a vaše vydavatelská zkušenost byla co nejpříjemnější. Pointa provize je odměna pro nás."
                : "Tým Pointy je vám během přípravy a v průběhu předprodeje i výroby knihy vždy při ruce, aby vaše vydavatelská zkušenost byla co nejpříjemnější. Pointa provize je odměna pro nás."}
            </Info>
            Provize za služby Pointy
          </div>
          <div className="col-sm-auto">
            <Text small>
              <b>{formatPrice(g(currentProject, "financing.entryFee", 0))}</b> Kč
            </Text>
          </div>
        </div>
      </Fragment>
    );
  }

  toggleModule = (module = "") => {
    const { modulesToShow } = this.state;
    this.setState({
      modulesToShow: modulesToShow.includes(module)
        ? modulesToShow.filter(m => m !== module)
        : [...modulesToShow, module]
    });
  };

  getState(module = "") {
    if (this.state.modulesToShow.includes(module)) {
      return "up";
    }
    return "down";
  }

  showCalculator(show) {
    this.setState({ showCalculator: show });
  }

  render() {
    const { project = {}, t, handshakeStates, showSelectFinancing, currentProject = {} } = this.props;
    // Asi by bylo dobré celé to předělat na z project na currentProject
    const { modulesToShow } = this.state;
    const { acceptedHandshakes, totalPrice } = g(project, "handshakes", []).reduce(
      (res, hs) => {
        if (g(handshakeStates, "accepted.name", "") === hs.state) {
          return {
            ...res,
            acceptedHandshakes: [...res.acceptedHandshakes, hs],
            totalPrice: res.totalPrice + hs.price
          };
        }
        return res;
      },
      {
        acceptedHandshakes: [],
        totalPrice: 0
      }
    );

    // totalPrice porovnat s contributorPrice
    const contributorPriceDiff =
      totalPrice +
      this.getContributorCommission(totalPrice) -
      ((currentProject.financing && currentProject.financing.contributorPrice) || 0);
    console.log(contributorPriceDiff ? `Contributor  price diff ${contributorPriceDiff}` : "contributor price OK");

    const depositAmount = this.state.deposit && this.state.deposit.amount ? this.state.deposit.amount : null;

    const flippingClasses = [];
    const flippingStyle = {};
    if (showSelectFinancing) {
      flippingClasses.push("flippingMode");
      flippingClasses.push("costflippingModeTransition");
      if (this.state.showCalculator) {
        flippingClasses.push("flipped");
      } else {
        if ('number' === typeof this.state.panelHeight) {
          flippingStyle.height = this.state.panelHeight;
        }
      }
    }

    // šipky dolů jsou řešené pomocí transform: rotateX(180deg) obrázku šipky nahoru, nejspíš dochází k nějakému
    // konfliktu dvou transformací, protože při flipu na výběr způsobu financování jsou šipky dolů vidět
    // vyřešeno skrývaním
    const hideArrows = showSelectFinancing && !this.state.showCalculator ? "hidden" : undefined;

    return (
      <Fragment>
        {showSelectFinancing && (
          <EditableState
            project={project}
            titleInside
            handleFinancingCallback={() => this.showCalculator(true)}
            flippingMode
            flipped={this.state.showCalculator}
            onSetHeight={(el) => {
              if (el) {
                const height = el.getBoundingClientRect().height - 20;
                if (height !== this.state.panelHeight) {
                  this.setState({ panelHeight: height });
                }
              }
            }}
          />
        )}
        <div className={bm(MODULE_NAME, undefined, flippingClasses)} style={flippingStyle}>
          <div className={be(MODULE_NAME, "title")}>
            {showSelectFinancing && (
              <span className="←toFundingSelect" onClick={() => this.showCalculator(false)}>
                <Text small light>
                  ← Zpět na <b>Výběr způsobu financování</b>
                </Text>
              </span>
            )}
            <Headline bold component="h2" xs>
              {t("projectDetail.costTitle")}
              {currentProject.funding === "crowd" && " pro předprodej"}
              {currentProject.funding === "own" && " pro samofinancování"}
            </Headline>
          </div>
          <div onClick={() => this.toggleModule("basePrice")}>
            <Headline component="h3" price primary uppercase xxs>
              NÁKLADY NA TISK{" "}
              <img className={be(MODULE_NAME, "arrow", [this.getState("basePrice"), hideArrows])} src="/images/svg/arrow_up.svg" />
            </Headline>
          </div>
          {modulesToShow.includes("basePrice") && (
            <div className="row">
              <div className="col-sm">
                <Info className="pr-2">
                  Cena za tisk knihy dle zadaných parametrů, včetně dopravy z tiskárny do skladů Pointy
                </Info>
                Tisk ve vybraném objemu
              </div>
              <div className="col-sm-auto">
                <Text small>
                  <b>{formatPrice(g(project, "financing.basePrice", 0))}</b> Kč
                </Text>
              </div>
            </div>
          )}
          <hr />
          <div className={be(MODULE_NAME, "colleagues")} onClick={() => this.toggleModule("colleagues")}>
            <Headline component="h3" price primary uppercase xxs>
              KOLEGOVÉ
              <img className={be(MODULE_NAME, "arrow", [this.getState("colleagues"), hideArrows])} src="/images/svg/arrow_up.svg" />
            </Headline>
          </div>
          {modulesToShow.includes("colleagues") && this.getColleagues(acceptedHandshakes)}
          {(!SHOW_COMMISSIONS_SEPARATELY && (
            <Text light small xs>
              Bez provize Pointa.cz <strong>{formatPrice(0.12 * totalPrice)}</strong> Kč (12%)
            </Text>
          )) ||
            ""}
          <hr />
          <div className={be(MODULE_NAME, "others")} onClick={() => this.toggleModule("others")}>
            <Headline component="h3" price primary uppercase xxs>
              DALŠÍ NÁKLADY{" "}
              <img className={be(MODULE_NAME, "arrow", [this.getState("others"), hideArrows])} src="/images/svg/arrow_up.svg" />
            </Headline>
          </div>
          {modulesToShow.includes("others") && this.getOtherCosts(acceptedHandshakes)}
          {SHOW_COMMISSIONS_SEPARATELY && (
            <Fragment>
              <hr />
              <div className={be(MODULE_NAME, "comissions")} onClick={() => this.toggleModule("comissions")}>
                <Headline component="h3" price primary uppercase xxs>
                  PROVIZE POINTY{" "}
                  <img className={be(MODULE_NAME, "arrow", [this.getState("comissions"), hideArrows])} src="/images/svg/arrow_up.svg" />
                </Headline>
              </div>
              {modulesToShow.includes("comissions") && this.getComissions(totalPrice)}
            </Fragment>
          )}

          <div className={be(MODULE_NAME, "price")}>
            {("own" !== currentProject.funding && (
              <div className="row">
                <div className="col-sm-2 d-flex flex-row">
                  <Info className="pr-2">
                    Součet všech uvedených nákladů na výrobu a vydání knihy dle zadaných parametrů při financování
                    formou crowdfundingové kampaně (předprodeje).
                  </Info>
                  <Text small>Celkové náklady</Text>
                </div>
                <div className="col-sm-10">
                  <div className="text-right">
                    <Text bold primary small>{`${formatPrice(g(currentProject, "financing.total", 0))} Kč`}</Text>
                    <br />
                    {/* <Text light xs small>Včetně DPH <strong>{formatPrice(g(project, 'financing.vat', 0))}</strong> Kč (15%)</Text> */}
                    {/* <Info className="pl-2">Za knihy, které spolu prodáme, musíme zaplatit DPH. Proto na něj nesmíme zapomínat už při předprodeji.</Info> */}
                  </div>
                </div>
              </div>
            )) ||
              ""}
            {("own" === currentProject.funding && (
              <Fragment>
                <div className="row">
                  <div className="col-sm">
                    <Text small>Celkové náklady bez dph</Text>
                  </div>
                  <div className="col-sm-auto">
                    <div className="text-right">
                      <Text small>
                        <b>{`${formatPrice(g(currentProject, "financing.total", 0))}`}</b> Kč
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <Info className="pr-2">
                      Součet všech nákladů na vydání knihy dle zadaných parametrů včetně fakturace kolegů, zajištění
                      výroby, vydání a distribuce knihy.
                    </Info>
                    <Text small>Celkové náklady vč. dph</Text>
                  </div>
                  <div className="col-sm-auto">
                    <div className="text-right">
                      <Text bold primary small>{`${formatPrice(g(currentProject, "financing.totalWithVat", 0))} Kč`}</Text>
                    </div>
                  </div>
                </div>
              </Fragment>
            )) ||
              ""}
          </div>

          {depositAmount !== null && currentProject.funding !== "own" && (
            <Fragment>
              <hr />
              <div style={{ marginTop: 20, marginBottom: 12, textAlign: "center" }}>
                <Headline bold component="h2" xs>
                  Záloha za uskutečnění předprodeje
                </Headline>
              </div>
              <div className="row">
                <div className="col-sm">
                  <Info className="pr-2">
                    Vratná záloha nám umožní pokrýt část nákladů spojených s přípravou předprodeje v případě, že by
                    náhodou předprodej nevyšel. Ale nebojte, pokud bude předprodej úspěšný, celá částka vám bude vrácena
                    zpět.
                  </Info>
                  Záloha
                </div>
                <div className="col-sm-auto mb-3">
                  <Text bold primary small>
                    <b>{formatPrice(depositAmount)}</b> Kč
                  </Text>
                </div>
              </div>
              <div>
                <Text light small xs>
                  Více informací o záloze{" "}
                  <a href="https://blog.pointa.cz/zaloha/" rel="noreferrer" target="_blank">
                    zde
                  </a>
                </Text>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

ProjectCosts.propTypes = {
  data: PropTypes.object.isRequired,
  handshakeStates: PropTypes.object.isRequired,
  project: PropTypes.object,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  handshakeStates: EnumsDuck.getHandhakeStates(state),
  currentProject: WizardDuck.getDetail(state),
});

export default compose(translate("translations"), connect(mapStateToProps))(ProjectCosts);
