import React from "react";

import "./statisticsLine.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

const MODULE_NAME = "StatisticsLine";

const StatisticsLine = ({ statistics, contributors, type }) =>
  contributors ? (
    <div style={{ background: "#DFEFF6", margin: "0 -1500px", padding: "50px 1500px", textAlign: "center" }}>
      <div className="row">
        {statistics
          .filter(item => item.type === "contributor")
          .map((item, key) => (
            <div key={item.id} className="col-sm d-flex flex-column" style={{ marginBottom: "30px", padding: "5px" }}>
              <Text greenReward statistics>
                {item.value}
              </Text>
              <Text greenReward semi>
                {item.name}
              </Text>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <div>
      {!isMobile() ? (
        <div className="row">
          {statistics
            .filter(item => item.type === ("string" === typeof type ? type : "author"))
            .slice(0, 3)
            .map((item, key) => (
              <div key={item.id} className="col-md-4 d-flex flex-column" style={{ marginBottom: "30px" }}>
                <Text greenReward statistics>
                  {item.value}
                </Text>
                <Text greenReward semi>
                  {item.name}
                </Text>
              </div>
            ))}
          {statistics
            .filter(item => item.type === ("string" === typeof type ? type : "author"))
            .slice(3, 5)
            .map((item, key) => (
              <div key={item.id} className="col-md-6 d-flex flex-column">
                <Text greenReward statistics>
                  {item.value}
                </Text>
                <Text greenReward semi>
                  {item.name}
                </Text>
              </div>
            ))}
        </div>
      ) : (
        <div>
          {statistics
            .filter(item => item.type === ("string" === typeof type ? type : "author"))
            .map((item, key) => (
              <div key={item.id}>
                <div className="row">
                  <div className="col-md-4 d-flex flex-column">
                    <Text greenReward statistics>
                      {item.value}
                    </Text>
                    <Text greenReward semi>
                      {item.name}
                    </Text>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );

export default StatisticsLine;
