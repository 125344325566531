import { get as g } from "lodash";
import CoreAPIService from "./coreAPIService";

class PageTextsService {
  static async getTexts(type) {
    return new Promise((resolve, reject) => {
      CoreAPIService.get(`pageTexts/${type}`)
        .then(response => {
          resolve(g(response, "data", []));
        })
        .catch(err => {
          window.alertify.notify(g(err, "response.data.message", ""), "error", 5);
          console.error(g(err, "response.data", ""));
          reject(err);
        });
    });
  }
}

export default PageTextsService;
