import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { get as g, maxBy, head } from "lodash";

import "./basicParameters.css";

import WizardService from "../../../services/wizardService";
import BasicParametersBubble from "../../../components/bubbles/basicParametersBubble/basicParametersBubble";
import RangeSlider from "../../../components/rangeSlider/rangeSlider";
import Tip from "../../../components/carousel/tips/tip";
import Link from "../../../components/link/link";
import Headline from "../../../components/headline/headline";
import { transformPrice } from "../../../utils/utils";
import UIDuck from "../../../redux/ducks/ui";
import Button from "../../../components/button/button";
import EnumsDuck from "../../../redux/ducks/enums";
import WizardDuck from "../../../redux/ducks/wizard";
import { bm, be } from "../../../utils/bliss";
import Text from "../../../components/text/text";

const MODULE_NAME = "BasicParameters";
const volumeDefaultValue = 500;

class BasicParametersPage extends Component {
  state = {
    selectedParam: null,
    howMany: volumeDefaultValue,
    price: 0,
    project: {},
    pages: 96
  };

  componentWillMount() {
    const {
      match: {
        params: { projectId }
      },
      genres
    } = this.props;
    WizardService.getProjectById(projectId).then(data => {
      const project = {
        ...data,
        genres: data.genres.map(gnr => ({
          ...gnr,
          ...g(genres, gnr.id, {})
        }))
      };

      this.setState(
        {
          project,
          howMany: g(data, "printer.copies", volumeDefaultValue),
          pages: g(data, "printer.pages", 96),
          selectedParam: g(data, "printer.binding.id", g(head(g(project, "genres", [])), "bookBinding.id", null))
        },
        () => {
          this.loadMoney();
        }
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalData.pages && g(nextProps, "modalData.pages", this.state.pages) !== this.state.pages) {
      this.setState({
        pages: g(nextProps, "modalData.pages", this.state.pages)
      });
    }
  }

  // openModal = () => {
  //     const { pages } = this.state;
  //     this.props.dispatch(UIDuck.openModal(UIDuck.modalTypes.bookPages, { pages }));
  // };

  confirm = () => {
    const { t, dispatch, history } = this.props;
    const { howMany, selectedParam, price, pages, project } = this.state;
    const printer = {
      copies: howMany,
      binding: selectedParam,
      price,
      pages
    };
    dispatch(
      WizardDuck.patchProject(project.id, {
        name: project.name,
        printer
      })
    ).then(() => {
      window.alertify.success(t("profile.askForCooperationConfirm"));
      history.push(`/project/${project.id}`);
    });
  };

  handleChange = (toState = {}, preventLoadMoney = false) => {
    this.setState(toState, () => {
      if (!preventLoadMoney) {
        this.loadMoney();
      }
    });
  };

  loadMoney = () => {
    const { selectedParam, pages, howMany } = this.state;
    const { bindings } = this.props;
    const bookType = g(
      bindings.find(b => b.id === selectedParam),
      "type",
      ""
    );

    WizardService.getProjectBasePrice(bookType, howMany, pages).then(res => {
      this.setState({
        price: res.cost
      });
    });
  };

  getNormPages() {
    const { pages, selectedParam } = this.state;
    const { bindings } = this.props;
    const bookType = g(
      bindings.find(b => b.id === selectedParam),
      "type",
      ""
    );

    switch (bookType) {
      case "A_AGATHA":
      case "B_ERNEST":
        return (pages / 1.2).toFixed(0);

      default:
        return (pages / 1.15).toFixed(0);
    }
  }

  render() {
    const {
      match: {
        params: { projectId }
      },
      t,
      bindings,
      tips
    } = this.props;
    const { selectedParam, howMany, price, project, pages } = this.state;
    const bookType = g(
      bindings.find(b => b.id === selectedParam),
      "name",
      ""
    );

    let info = project.name || "";
    const option = bindings.find(opt => opt.id === selectedParam);

    if (option) {
      info += `, ${t(`basicParameters.info`, { data: option.name })}`;
    }
    if (howMany) {
      info += `, ${howMany} kusů`;
    }
    if (pages) {
      info += `, ${pages} stránek`;
    }

    return (
      <div className={bm(MODULE_NAME)}>
        <Link to={`/project/${projectId}`}>&#x2039; {t("basicParameters.back")}</Link>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <Headline bold page>
          {t("basicParameters.title")}
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <Headline component="h2" pageSub>
          {t("basicParameters.label1")}
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        {bindings
          .map((opt, i) => ({
            ...opt,
            title: opt.name,
            label: opt.description,
            description: `${opt.size}, ${opt.parameters}`,
            icon: `/images/svg/package${i}.svg`
          }))
          .map(opt => (
            <BasicParametersBubble
              key={opt.id}
              onSelect={() => this.handleChange({ selectedParam: opt.id })}
              selected={selectedParam === opt.id}
              {...opt}
            />
          ))}
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "spacing", "lg")} />
        <Headline component="h2" pageSub>
          {t("basicParameters.label2")}
          <Text normal xl>
            {" "}
            {howMany} kusů
          </Text>
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "slider")}>
          <RangeSlider
            label={`${howMany} kusů`}
            max={2000}
            min={500}
            onChange={value => this.handleChange({ howMany: value }, true)}
            onChangeComplete={this.loadMoney}
            step={500}
            value={howMany}
          />
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <div className="row">
          <div className="col-sm-8 offset-sm-2">
            <Tip text={g(g(tips, "basicParameters", [])[0], "text", "")} />
          </div>
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <Headline component="h2" pageSub>
          {t("modals.pageCount.normPagesLabel")}
          <Text normal xl>
            {" "}
            {pages} stran
          </Text>
        </Headline>
        <div className={be(MODULE_NAME, "spacing", "sm")} />
        <div className={be(MODULE_NAME, "slider")}>
          <RangeSlider
            className="w-100"
            label={`${pages} stran`}
            max={528}
            min={48}
            onChange={value => this.handleChange({ pages: value }, true)}
            onChangeComplete={this.loadMoney}
            step={16}
            value={pages}
          />
          <div className={be(MODULE_NAME, "spacing", "sm")} />
          <Text component="div" primary small>
            {t("modals.pageCount.description", {
              bookType,
              pages,
              normoPages: this.getNormPages()
            })}
          </Text>
        </div>
        <div className={be(MODULE_NAME, "spacing", "md")} />
        <div className="row">
          <div className="col-sm-8 offset-sm-2">
            <Tip text={g(g(tips, "basicParameters", [])[1], "text", "")} />
          </div>
        </div>
        {/* <div className={be(MODULE_NAME, 'spacing', 'lg')}/> */}
        {/* <Headline pageSub component='h2'>{t('basicParameters.pagesCount')} <Text xl normal>{pages}</Text></Headline> */}
        {/* <span className={be(MODULE_NAME, 'count')} onClick={this.openModal}>Znám přesný počet stránek</span> */}
        <div className={be(MODULE_NAME, "spacing", "xl", "d-none d-md-block")} />
        <div className={be(MODULE_NAME, "info")}>
          <div className={be(MODULE_NAME, "infoContent")}>
            <div className="container">
              <div className="row">
                <div className="col-md text-center text-md-left">
                  <Text bold component="div" primary>
                    {t("basicParameters.infoTitle")}
                  </Text>
                  <div className={be(MODULE_NAME, "spacing", "xs")} />
                  <Text small>{info}</Text>
                  <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                  <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                </div>
                <div className="col-md text-center text-md-right">
                  <Text bold small>
                    {transformPrice(parseFloat(price))} Kč
                  </Text>
                  <div className={be(MODULE_NAME, "spacing", "xs", "d-md-none")} />
                  <Button mini onClick={this.confirm} spacingLeft>
                    {t("basicParameters.done")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicParametersPage.propTypes = {
  bindings: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  genres: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  modalData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tips: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  modalData: UIDuck.getModalData(state),
  bindings: EnumsDuck.getBindings(state),
  genres: EnumsDuck.getGenres(state),
  tips: EnumsDuck.getTips(state)
});

export default compose(translate("translations"), connect(mapStateToProps))(BasicParametersPage);
