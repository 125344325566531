import React from "react";
import PropTypes from "prop-types";

import "./ourBenefits.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";

const MODULE_NAME = "OurBenefits";

const COLUMNS = 2;

const OurBenefits = props => {
  const { benefits, headlineText } = props;
  if (!Array.isArray(benefits)) {
    return null;
  }
  const benefitsPerColumn = Math.ceil(benefits.length / COLUMNS);

  const columnedBenefits = [];
  // Toto nelze použít, protože to smaže obsah z props a při příštím rerenderu je pole prázdné
  // while (benefitsPerColumn > 0) {
  //   columnedBenefits.push(benefits.splice(0, benefitsPerColumn));
  // }
  while (columnedBenefits.length < COLUMNS) {
    const part = columnedBenefits.length;
    columnedBenefits.push(benefits.slice(part * benefitsPerColumn, (part + 1) * benefitsPerColumn));
  }

  return (
    <div style={{ background: "#ffffff", margin: "0 -1500px", padding: "50px 1500px", textAlign: "center" }}>
      <div className="mb-5 text-center">
        <Headline greenReward>
          <span class="headlineTextContainer" dangerouslySetInnerHTML={{__html: headlineText}}/>
        </Headline>
      </div>
      <div className="row">
        {columnedBenefits.map((benefitSet, i) => (
          <div key={`set${i}`} className={isMobile() ? "col-md-6 d-flex" : "col-md-6 d-flex justify-content-center"}>
            <div>
              {benefitSet.map((benefitText, j) => (
                <div key={`set-item${i}-${j}`} className="d-flex align-items-center pb-4">
                  <p className={be(MODULE_NAME, "number")}>{i * benefitsPerColumn + j + 1}</p>
                  <Text greenReward small left bold>
                    <span className="advantageTextContainer" dangerouslySetInnerHTML={{ __html: benefitText }} />
                  </Text>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

OurBenefits.defaultProps = {
  headlineText: "10 výhod spolupráce s Pointou",
  benefits: [
    "Knihu můžeš vydat jednoduše online",
    "Peníze na vydání vybíráš pomocí crowdfundingu",
    "U nás si najdeš i vybereš všechny knižní kolegy",
    "Najdeš u nás komunitu betačtenářů",
    "Celková podoba knihy je jen ve tvých rukách",
    "Dostaneš vyšší provize a lepší podmínky",
    "Vyřešíme za tebe smlouvy i další administrativu",
    "Vypočítáme ti celkové náklady na knihu",
    "Zajistíme tisk, ISBN a zaslání povinných výtisků",
    'Distribuujeme do knihkupectví i&nbsp;<span style="white-space: nowrap;">e-shopů</span> v Česku i na Slovensku'
  ]
};

OurBenefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  headlineText: PropTypes.string
};

export default OurBenefits;
