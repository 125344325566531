import React from "react";
import PropTypes from "prop-types";

import "./howTo.css";

import { isMobile } from "../../../../utils/utils";
import { be } from "../../../../utils/bliss";
import Headline from "../../../../components/headline/headline";
import Text from "../../../../components/text/text";
import Button from "../../../../components/button/button";

const MODULE_NAME = "HowTo";

const HowTo = props =>
  !isMobile() ? (
    <div style={{ padding: "50px 0", textAlign: "center" }}>
      <div className="text-center mb-5">
        <Headline greenReward>{props.title}</Headline>
      </div>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>1</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  {props.titles[0]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[0] } } className={be(MODULE_NAME, "🪞")} />{" "}
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start" style={{ padding: "140px 0 170px 0" }}>
              <div className={be(MODULE_NAME, "number")}>3</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  {props.titles[2]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[2] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>5</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  {props.titles[4]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    {" "}
                    <span dangerouslySetInnerHTML={{ __html: props.texts[4] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center" style={{ width: "20%" }}>
          <p className={be(MODULE_NAME, "middleLine")} />
          <p className={be(MODULE_NAME, "middleSquareOne")} />
          <p className={be(MODULE_NAME, "middleSquareTwo")} />
          <p className={be(MODULE_NAME, "middleSquareThree")} />
          <p className={be(MODULE_NAME, "middleSquareFour")} />
          <p className={be(MODULE_NAME, "arrowSymbol")}>&#8595;</p>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div style={{ padding: "140px 0" }}>
            <div className="d-flex align-items-start" style={{ paddingBottom: "140px" }}>
              <div className={be(MODULE_NAME, "number")}>2</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  {props.titles[1]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[1] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>4</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward midSemi>
                  {props.titles[3]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[3] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: "75px" }}>
        <Button small smallRadius xl href="/register/author">
          Chci vydat knihu
        </Button>
      </div>
    </div>
  ) : (
    <div style={{ padding: "50px 0", textAlign: "center" }}>
      <div className="text-center mb-4">
        <Headline greenReward>{props.title}</Headline>
      </div>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>1</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward small>
                  {props.titles[0]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[0] }} className={be(MODULE_NAME, "🪞")} />{" "}
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start" style={{ padding: "40px 0" }}>
              <div className={be(MODULE_NAME, "number")}>2</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward small>
                  {props.titles[1]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[1] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>3</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward small>
                  {props.titles[2]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[2] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <div>
            <div className="d-flex align-items-start" style={{ padding: "40px 0" }}>
              <div className={be(MODULE_NAME, "number")}>4</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward small>
                  {props.titles[3]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    <span dangerouslySetInnerHTML={{ __html: props.texts[3] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className={be(MODULE_NAME, "number")}>5</div>
              <div className="d-flex flex-column text-left" style={{ paddingTop: "15px" }}>
                <Text bold greenReward small>
                  {props.titles[4]}
                </Text>
                <span style={{ paddingTop: "10px" }}>
                  <Text greenReward small>
                    {" "}
                    <span dangerouslySetInnerHTML={{ __html: props.texts[4] }} className={be(MODULE_NAME, "🪞")} />
                  </Text>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: "25px" }}>
        <Button small smallRadius xl href="/register/author">
          Chci vydat knihu
        </Button>
      </div>
    </div>
  );

HowTo.defaultProps = {
  title: "Jak vydat knihu s Pointou",
  titles: [
    "Založ si profil a nahraj projekt",
    "Sestav si knižní tým",
    "Spolu knížce dáme formu",
    "Vybíráme peníze na vydání",
    "Pošleme ji do světa",
  ],
  texts: [
    "Založ si u nás autorský účet a nahraj svůj knižní projekt. Aby se čtenáři i kolegové mohli o knize dozvědět víc, nahraj i anotaci knihy a nějakou ukázku. Zároveň už si nastavíš i parametry knihy.",
    "Poskládej si tým, který bude na knize pracovat s tebou. Základ je mít grafika, sazeče, redaktorku a korektora a vybírat můžeš i z ilustrátorů, markeťáků a jiných. Po domluvě spolu snadno a online podepíšete smlouvu jedním kliknutím.",
    "Náš tým ti poradí s nákladem, formátem, cenou i smlouvami. Ty s námi vyřešíš rychle, jednoduše a budeš je mít výhodnější než jinde.",
    "Čtenáři budou tvoji knihu podporovat v předprodeji, aby se vybraly potřebné peníze. Pokud se cílová částka vybere, začneš s týmem pracovat na knize. Jinak čtenářům vrátíme peníze v plné výši.",
    "Po úspěšném předprodeji a výrobě tvoji knihu dostaneme do knihkupectví a e-shopů v Česku i na Slovensku. Navíc u nás dostaneš vyšší provize z prodeje, než u tradičních nakladatelů.",
  ]
};

export default HowTo;
