import React from "react";

import "./reviews.css";
import { be } from "../../../../utils/bliss";
import { PREVIEWS_API } from "../../../../services/variables";
import MobileSlider from "../../../../components/mobileSlider/mobileSlider";
import Text from "../../../../components/text/text";

const MODULE_NAME = "Reviews";

const ReviewsPagination = ({ reviews, authors, type = authors ? "authorContributorReview" : "contributorReview" }) =>
  authors ? (
    <div className="col-12" style={{ margin: "0" }}>
      <MobileSlider
        responsive={[
          {
            breakpoint: 480,
            infinite: false,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]}
      >
        {reviews
          .filter(item => item.type === type)
          .slice(0, 4)
          .map((item, key) => (
            <div className="col-12 mobileSlider-slide" key={item.id}>
              <div className="col-12 mb-3">
                <div className={be(MODULE_NAME, "wrapper")}>{item.text}</div>
                <div className={be(MODULE_NAME, "main")}>
                  <div className={be(MODULE_NAME, "photoLeft")}>
                    <img className={be(MODULE_NAME, "userPic")} src={`${PREVIEWS_API}${item.image}`} alt="userPic" />
                  </div>
                  <div className={be(MODULE_NAME, "userInfo")}>
                    <div className={be(MODULE_NAME, "userName")}>
                      <Text bold greenReward small float>
                        {item.name}
                      </Text>
                    </div>
                    <div className={be(MODULE_NAME, "userRole")}>
                      <Text greenReward small>
                        {item.role}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </MobileSlider>
    </div>
  ) : (
    <div className="col-12" style={{ margin: "0" }}>
      <MobileSlider
        responsive={[
          {
            breakpoint: 480,
            infinite: false,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]}
      >
        {reviews
          .filter(item => item.type === "contributorReview")
          .slice(0, 4)
          .map((item, key) => (
            <div className="col-12 mobileSlider-slide" key={item.id}>
              <div className="col-12 mb-3">
                <div className={be(MODULE_NAME, "wrapper")}>{item.text}</div>
                <div className={be(MODULE_NAME, "main")}>
                  <div className={be(MODULE_NAME, "photoLeft")}>
                    <img className={be(MODULE_NAME, "userPic")} src={`${PREVIEWS_API}${item.image}`} alt="userPic" />
                  </div>
                  <div className={be(MODULE_NAME, "userInfo")}>
                    <div className={be(MODULE_NAME, "userName")}>
                      <Text bold greenReward small float>
                        {item.name}
                      </Text>
                    </div>
                    <div className={be(MODULE_NAME, "userRole")}>
                      <Text greenReward small>
                        {item.role}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </MobileSlider>
    </div>
  );

export default ReviewsPagination;
