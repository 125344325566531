import React from "react";
import { get as g } from "lodash";

import { connect } from "react-redux";

import EnumsDuck from "../../../../redux/ducks/enums";
import UserDuck from "../../../../redux/ducks/user";

import { getProjectDetailSubroles } from "../../../../utils/utils";

import ContributorHandshake from "../../../../components/contributorHandshake/contributorHandshake";
import FundingInfo from "../../../../components/fundingInfo/fundingInfo";
import ProjectCosts from "../../../../components/projectCosts/projectCosts";
import ProjectPrices from "../../../../components/projectPrices/projectPrices";
import ProjectReleased from "../../../../components/projectReleased/projectReleased";
import ProjectProgress from "../../../../components/projectProgress/projectProgress";

class GetInfoTable extends React.Component {
  constructor() {
    super();
    this.state = {
      showHandshakeRequest: false
    };
  }

  toggleHandshakeRequest = showHandshakeRequest => {
    this.setState({
      showHandshakeRequest
    });
  };

  render() {
    const { project, user, projectStates, genres, scrollToMyRef, showSelectFinancing } = this.props;
    const { showHandshakeRequest } = this.state;
    const isEditable =
      g(project, "author", []).find(author => author.id === user.id) &&
      g(project, "state", "") === g(projectStates, "proposal.name", "");
    const projectState = g(project, "state", "");
    const data = getProjectDetailSubroles(project, genres);

    if (g(projectStates, "released.name", "") === projectState) {
      return <ProjectReleased project={project} />;
    }
    if (g(projectStates, "inProgress.name", "") === projectState) {
      return <ProjectProgress project={project} />;
    }
    if (g(projectStates, "proposal.name", "") === projectState && isEditable) {
      return <ProjectCosts data={data} project={project} showSelectFinancing={showSelectFinancing} />;
    }
    if (g(projectStates, "failed.name", "") === projectState) {
      return (
        <ProjectPrices
          project={project}
          required={data.required}
          showMore={g(project, "state", "") !== g(projectStates, "funding.name", "")}
        />
      );
    }
    return (
      <React.Fragment>
        {!showHandshakeRequest && project.type !== "financing" && (
          <ProjectPrices
            project={project}
            required={data.required}
            showHandshakeRequest={showHandshakeRequest}
            showHandshakeRequestButton={g(user, "subroles", []).length > 0 && user.isVerifiedContributor}
            showMore={g(project, "state", "") !== g(projectStates, "funding.name", "")}
            toggleHandshakeRequest={this.toggleHandshakeRequest}
          />
        )}
        {!showHandshakeRequest && project.type === "financing" && (
          <FundingInfo
            onClick={scrollToMyRef}
            project={project}
            required={data.required}
            showHandshakeRequest={showHandshakeRequest}
            showHandshakeRequestButton={g(user, "subroles", []).length > 0 && user.isVerifiedContributor}
            showMore={g(project, "state", "") !== g(projectStates, "funding.name", "")}
            toggleHandshakeRequest={this.toggleHandshakeRequest}
          />
        )}
        {showHandshakeRequest && (
          <ContributorHandshake
            toggleHandshakeRequest={this.toggleHandshakeRequest}
            toggleShowSuccessTopBar={this.toggleShowSuccessTopBar}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: UserDuck.getUser(state),
  genres: EnumsDuck.getGenres(state),
  projectStates: EnumsDuck.getProjectStates(state)
});

export default connect(mapStateToProps)(GetInfoTable);
