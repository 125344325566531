import { get as g, isEmpty } from "lodash";
import { push, goBack } from "react-router-redux";

import { createActionTypes } from "../createActionTypes";
import WizardService from "../../services/wizardService";
import { PREVIEWS_API } from "../../services/config";
import EnumsDuck from "./enums";
import { detectIE } from "../../utils/utils";
import UserDuck from "./user";

const WizardDuck = {
  name: "Wizard"
};

const initialState = {
  step: 1,
  maxStep: 5,
  genres: [],
  tags: [],
  title: "",
  annotation: "",
  introAnnotation: "",
  files: [],
  editor: "",
  canGoBack: true,
  projects: {
    page: 1,
    limit: 16, // The maximum number of items allowed per page.
    count: 0, // The number of items recieved for this page.
    total: 0, // The total avilable for current filters.
    order: "desc"
  },
  projectsGroups: {},
  userProjects: {},
  followedProjects: {
    limit: 9,
    page: 1,
    order: "desc"
  },
  interestedProjects: {},
  editing: "",
  loading: {
    followedProjects: false,
    interestedProjects: false,
    userProjects: true,
    projects: true,
    projectsGroups: true,
    detail: true
  },
  detail: {},
  detailNews: {
    limit: 3,
    page: 1,
    order: "desc"
  },
  detailRewards: {}
};

const actionTypes = createActionTypes(
  {
    setGenres: "set_genres",
    setTags: "set_tags",
    setStep: "set_step",
    setTitle: "set_title",
    setAnnotation: "set_annotation",
    setFiles: "set_files",
    setEditor: "set_editor",
    setProjects: "set_projects",
    setProjectsChecklists: "set_projects_checklists",
    setProjectsGroups: "set_projects_groups",
    setProjectsGroupsChecklists: "set_projects_groups_checklists",
    addProject: "add_project",
    resetWizard: "reset_wizard",
    setUserProjects: "set_user_projects",
    addUserProject: "add_user_projects",
    editProject: "edit_project",
    replaceProject: "replace_project",
    setLoading: "set_loading",
    setDetail: "set_detail",
    updateDetail: "update_detail",
    replaceProjectFromProjects: "replace_project_from_projects",
    followProject: "follow_project",
    unfollowProject: "unfollow_project",
    setFollowedProjects: "set_followed_projects",
    setInterestedProjects: "set_interested_projects",
    addNews: "add_news",
    removeNews: "remove_news",
    setDetailNews: "set_detail_news",
    setDetailRewards: "set_detail_rewards",
    addToFollowedProjects: "add_to_followed_projects"
  },
  WizardDuck.name
);

WizardDuck.reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setLoading:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.entity]: action.payload
        }
      };

    case actionTypes.resetWizard:
      return {
        ...state,
        ...initialState
      };

    case actionTypes.setGenres:
      return {
        ...state,
        genres: action.payload
      };

    case actionTypes.setTags:
      return {
        ...state,
        tags: action.payload
      };

    case actionTypes.setStep:
      return {
        ...state,
        step: action.payload
      };

    case actionTypes.setTitle:
      return {
        ...state,
        title: action.payload
      };

    case actionTypes.setAnnotation:
      return {
        ...state,
        annotation: action.payload
      };

    case actionTypes.setFiles:
      return {
        ...state,
        files: action.payload
      };

    case actionTypes.setEditor:
      return {
        ...state,
        editor: action.payload
      };

    case actionTypes.setProjects:
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, project) => ({
              ...res,
              [project.id]: project
            }),
            {}
          )
        }
      };

    case actionTypes.setProjectsGroups:
      return {
        ...state,
        projectsGroups: {
          ...state.projectsGroups,
          ...action.payload
        }
      };

    case actionTypes.setProjectsChecklists:
      return {
        ...state,
        projects: {
          ...state.projects,
          ...action.payload
        }
      };

    case actionTypes.setProjectsGroupsChecklists:
      return {
        ...state,
        projectsGroups: {
          ...state.projectsGroups,
          ...action.payload
        }
      };

    case actionTypes.replaceProject:
      return {
        ...state,
        projects: {
          ...state.projects,
          data: {
            ...g(state, "projects.data", {}),
            [action.payload.id]: action.payload
          }
        },
        userProjects: {
          ...state.userProjects,
          data: {
            ...g(state, "userProjects.data", {}),
            [action.payload.id]: action.payload
          }
        }
      };

    case actionTypes.replaceProjectFromProjects:
      let toSave = {};
      if (g(state, `userProjects.data.${action.payload.id}`, false)) {
        toSave = {
          ...toSave,
          userProjects: {
            ...state.userProjects,
            data: {
              ...g(state, "userProjects.data", {}),
              [action.payload.id]: action.payload
            }
          }
        };
      }
      if (g(state, `interestedProjects.data.${action.payload.id}`, false)) {
        toSave = {
          ...toSave,
          interestedProjects: {
            ...state.userProjects,
            data: {
              ...g(state, "interestedProjects.data", {}),
              [action.payload.id]: action.payload
            }
          }
        };
      }
      if (g(state, `followedProjects.data.${action.payload.id}`, false)) {
        toSave = {
          ...toSave,
          followedProjects: {
            ...state.userProjects,
            data: {
              ...g(state, "followedProjects.data", {}),
              [action.payload.id]: action.payload
            }
          }
        };
      }
      const projectState = g(action, "meta.projectState", false);
      if (
        projectState &&
        g(state, `projectsGroups.${projectState}`, []).filter(item => item.id === action.payload.id)
      ) {
        toSave = {
          ...toSave,
          projectsGroups: {
            ...state.projectsGroups,
            [projectState]: g(state, `projectsGroups.${projectState}`, []).map(item => {
              if (item.id === action.payload.id) {
                return { ...item, ...action.payload };
              }
              return item;
            })
          }
        };
      }
      return {
        ...state,
        projects: {
          ...state.projects,
          data: {
            ...g(state, "projects.data", {}),
            [action.payload.id]: action.payload
          }
        },
        ...toSave
      };

    case actionTypes.setUserProjects:
      return {
        ...state,
        userProjects: {
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, project) => ({
              ...res,
              [project.id]: project
            }),
            {}
          )
        }
      };

    case actionTypes.addUserProject:
      return {
        ...state,
        userProjects: {
          ...action.payload,
          data: g(state, "userProjects.data", []).map(uProj => {
            if (uProj.id === action.payload.id) {
              return action.payload.id;
            }
            return uProj;
          })
        }
      };

    case actionTypes.addProject:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.id]: action.payload
        }
      };

    case actionTypes.editProject:
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.setDetail:
      return {
        ...state,
        detail: { ...state.detail, ...action.payload }
      };

    case actionTypes.updateDetail:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload
        }
      };

    case actionTypes.setFollowedProjects:
      return {
        ...state,
        followedProjects: {
          ...state.followedProjects,
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, project) => ({
              ...res,
              [project.id]: project
            }),
            {}
          )
        }
      };

    case actionTypes.addToFollowedProjects:
      return {
        ...state,
        followedProjects: {
          ...state.followedProjects,
          ...action.payload
        }
      };

    case actionTypes.setInterestedProjects:
      return {
        ...state,
        interestedProjects: {
          ...state.interestedProjects,
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, project) => ({
              ...res,
              [project.id]: project
            }),
            {}
          )
        }
      };

    case actionTypes.followProject:
      return {
        ...state,
        followedProjects: {
          ...state.userProjects,
          data: {
            ...g(state, "followedProjects.data", {}),
            [action.payload.id]: action.payload
          }
        }
      };

    case actionTypes.unfollowProject:
      const followedProjects = g(state, "followedProjects.data", {});
      delete followedProjects[action.payload.id];
      return {
        ...state,
        followedProjects: {
          ...state.followedProjects,
          data: followedProjects
        }
      };

    case actionTypes.addNews:
      return {
        ...state,
        detailNews: {
          ...state.detailNews,
          data: {
            ...g(state, "detailNews.data", {}),
            [action.payload.id]: action.payload
          }
        }
      };

    case actionTypes.removeNews:
      const news = g(state, "detailNews.data", {});
      delete news[action.payload];
      return {
        ...state,
        detailNews: {
          ...state.detailNews,
          data: news
        }
      };

    case actionTypes.setDetailNews:
      return {
        ...state,
        detailNews: {
          ...g(state, "detailNews", {}),
          ...action.payload,
          data: g(action, "payload.data", []).reduce(
            (res, project) => ({
              ...res,
              [project.id]: project
            }),
            {}
          )
        }
      };
    case actionTypes.setDetailRewards:
      return {
        ...state,
        detailRewards: {
          ...g(state, "detailRewards", {}),
          ...action.payload,
          rewards: g(action, "payload.rewards", []).reduce(
            (res, project) => ({
              ...res,
              [project.order_inx]: project
            }),
            {}
          )
        }
      };

    default:
      return state;
  }
};

WizardDuck.setGenres = (genres = []) => ({
  type: actionTypes.setGenres,
  payload: genres
});

WizardDuck.setTags = (tags = []) => ({
  type: actionTypes.setTags,
  payload: tags
});

WizardDuck.setStep = step => ({
  type: actionTypes.setStep,
  payload: step
});

WizardDuck.setTitle = (title = "") => ({
  type: actionTypes.setTitle,
  payload: title
});

WizardDuck.setAnnotation = (text = "") => ({
  type: actionTypes.setAnnotation,
  payload: text
});

WizardDuck.setFiles = (files = []) => (dispatch, getState) => {
  const state = getState();
  const currentFiles = WizardDuck.getFiles(state); // .map(f => f.id);
  const projectId = getOwnState(state).editing || "";

  if (currentFiles.length > files.length) {
    // file deleted
    const filesIds = files.map(f => f.id);
    const promises = currentFiles
      .filter(cf => !filesIds.includes(cf.id))
      .map(({ id }) => {
        if (id) {
          return WizardService.deleteProjectSample(projectId, id);
        }
        return new Promise(res => res());
      });
    Promise.all(promises).then(() => {
      window.alertify.success("Soubor úspěšně smazán.", 5);
      dispatch({
        type: actionTypes.setFiles,
        payload: files
      });
    });
  } else {
    // file added
    dispatch({
      type: actionTypes.setFiles,
      payload: files
    });
  }
};

WizardDuck.setEditor = (value = "") => ({
  type: actionTypes.setEditor,
  payload: value
});

WizardDuck.setLoading = (entity, isLoading) => ({
  type: actionTypes.setLoading,
  payload: isLoading,
  entity
});

WizardDuck.fetchAllProjects = (opt = initialState.projects) => (dispatch, getState) => {
  dispatch(WizardDuck.setLoading("projects", true));
  return WizardService.fetchProjects(opt).then(data => {
    if (detectIE()) {
      // PP-680
      // mother fucking nasty hack for IE
      const projects = WizardDuck.getProjects(getState());
      if (!isEmpty(projects) && isEmpty(data.data)) {
        dispatch(WizardDuck.setLoading("projects", false));
        return;
      }
    }
    dispatch({
      type: actionTypes.setProjects,
      payload: {
        ...data,
        ...opt
      }
    });
    dispatch(WizardDuck.fetchAllProjectsChecklists(data.data));
    // dispatch(WizardDuck.setLoading('projects', false));
  });
};
// eslint-disable-next-line no-unused-vars
WizardDuck.fetchAllProjectsChecklists = projects => (dispatch, getState) => {
  dispatch(WizardDuck.setLoading("projects", true));
  const projectIds = [];
  Object.values(projects).forEach(p => projectIds.push(p.id));
  return WizardService.getAllProjectChecklists(projectIds).then(data => {
    const newProjects = { ...projects };
    Object.keys(newProjects).forEach(key => {
      const project = newProjects[key];
      newProjects[key].projectChecklist = data[project.id];
    });

    dispatch({
      type: actionTypes.setProjectsChecklists,
      payload: newProjects
    });
    dispatch(WizardDuck.setLoading("projects", false));
  });
};

WizardDuck.fetchAllProjectsGroups = (opt = initialState.projectsGroups) => (dispatch, getState) => {
  dispatch(WizardDuck.setLoading("projectsGroups", true));
  return WizardService.fetchProjects({
    ...opt,
    ...{ groupByState: true }
  }).then(data => {
    if (detectIE()) {
      // PP-680
      // mother fucking nasty hack for IE
      const projectsGroups = WizardDuck.getProjectsGroups(getState());
      if (!isEmpty(projectsGroups) && isEmpty(data)) {
        dispatch(WizardDuck.setLoading("projectsGroups", false));
        return;
      }
    }
    dispatch({
      type: actionTypes.setProjectsGroups,
      payload: data
    });
    dispatch(WizardDuck.fetchAllProjectsGroupsChecklists(data));
    // dispatch(WizardDuck.setLoading('projectsGroups', false));
  });
};

WizardDuck.fetchAllProjectsGroupsChecklists = projectsGroups => (dispatch, getState) => {
  dispatch(WizardDuck.setLoading("projectsGroups", true));
  const projectIds = [];
  Object.values(projectsGroups).forEach(g => g.forEach(p => projectIds.push(p.id)));
  return WizardService.getAllProjectChecklists(projectIds).then(data => {
    const newProjectsGroups = { ...projectsGroups };
    Object.keys(newProjectsGroups).forEach(key => {
      Object.keys(newProjectsGroups[key]).forEach(pkey => {
        const project = newProjectsGroups[key][pkey];
        newProjectsGroups[key][pkey].projectChecklist = data[project.id];
      });
    });

    dispatch({
      type: actionTypes.setProjectsGroupsChecklists,
      payload: newProjectsGroups
    });
    dispatch(WizardDuck.setLoading("projectsGroups", false));
  });
};

WizardDuck.fetchUserProjects = (userId = "") => dispatch =>
  new Promise((res, rej) => {
    dispatch(WizardDuck.setLoading("userProjects", true));
    WizardService.fetchUserProjects(userId).then(data => {
      res(data);
      dispatch({
        type: actionTypes.setUserProjects,
        payload: data
      });
      dispatch(WizardDuck.setLoading("userProjects", false));
    });
  });

WizardDuck.submit = () => (dispatch, getState) => {
  const data = getOwnState(getState());
  const isEditing = data.editing;
  const result = {
    name: data.title,
    annotation: data.annotation,
    tags: data.tags,
    genres: data.genres,
    sample: data.editor,
    sampleFiles: g(data, "files", []).map(f => f.data)
  };
  dispatch(WizardDuck.setLoading("userProjects", true));
  if (!isEditing) {
    WizardService.createProject(result).then(project => {
      window.alertify.notify("Projekt byl vytvořen!", "success", 5);
      dispatch({
        type: actionTypes.resetWizard
      });
      dispatch({
        type: actionTypes.addProject,
        payload: project
      });
      dispatch({
        type: actionTypes.addUserProject,
        payload: project
      });
      dispatch(WizardDuck.setLoading("userProjects", false));
      dispatch(push("/dashboard"));
    });
  } else {
    WizardService.editProject(isEditing, result).then(project => {
      window.alertify.notify("Projekt byl upraven!", "success", 5);
      dispatch({
        type: actionTypes.resetWizard
      });
      dispatch({
        type: actionTypes.replaceProject,
        payload: project
      });
      dispatch(WizardDuck.setLoading("userProjects", false));
      dispatch(goBack());
    });
  }
};

WizardDuck.deleteProject = project => (dispatch, getState) => {
  const state = getState();
  const projects = WizardDuck.getProjects(state);
  const userProjects = WizardDuck.getUserProjects(state);
  dispatch(WizardDuck.setLoading("userProjects", true));
  WizardService.deleteProject(project.id).then(() => {
    dispatch({
      type: actionTypes.setProjects,
      payload: Object.values(projects).filter(pr => pr.id !== project.id)
    });
    dispatch({
      type: actionTypes.setUserProjects,
      payload: Object.values(userProjects).filter(pr => pr.id !== project.id)
    });
    dispatch(WizardDuck.setLoading("userProjects", false));
    dispatch(push("/dashboard"));
  });
};

WizardDuck.editProject = (project = {}) => {
  const canGoBack = isEmpty(g(project, "handshakes", []));
  const payload = {
    genres: project.genres.map(g => g.id) || [],
    tags: project.tags.map(t => t.id) || [],
    title: project.name || "",
    annotation: project.annotation,
    files: g(project, "sampleFiles", []).map(sf => ({
      ...sf,
      preview: `${PREVIEWS_API}${sf.data}`
    })),
    editor: project.sample || "",
    editing: project.id,
    canGoBack,
    step: canGoBack ? 1 : 3
  };

  return {
    type: actionTypes.editProject,
    payload
  };
};

WizardDuck.patchProject = (projectId = "", dataParam = {}, isDetail, dontReload) => (dispatch, getState) => {
  if (isDetail && !dontReload) {
    dispatch(WizardDuck.setLoading("detail", true));
  }
  return WizardService.editProject(projectId, dataParam).then(data => {
    const projectStates = EnumsDuck.getProjectStates(getState());
    if (g(projectStates, "inProgress.name", "") === data.state) {
      WizardService.getProjectChecklist(projectId).then(projectChecklist => {
        dispatch({
          type: actionTypes.replaceProject,
          payload: {
            ...data,
            projectChecklist
          }
        });
        if (isDetail) {
          dispatch({
            type: actionTypes.updateDetail,
            payload: {
              ...data,
              projectChecklist
            }
          });
          dispatch(WizardDuck.setLoading("detail", false));
        }
      });
    } else {
      dispatch({
        type: actionTypes.replaceProject,
        payload: data
      });
      if (isDetail) {
        dispatch({
          type: actionTypes.updateDetail,
          payload: data
        });
        dispatch(WizardDuck.setLoading("detail", false));
      }
    }
  });
};

WizardDuck.fetchMoreProjects = (filters = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).projects;
  dispatch(
    WizardDuck.fetchAllProjects({
      page: parseInt(/page=(\d)/.exec(g(state, "links.next", ""))[1], 10),
      limit: 9,
      ...filters
    })
  );
};

WizardDuck.fetchLessProjects = (filters = {}) => (dispatch, getState) => {
  const state = getOwnState(getState()).projects;
  dispatch(
    WizardDuck.fetchAllProjects({
      page: parseInt(/page=(\d)/.exec(g(state, "links.prev", ""))[1], 10),
      limit: 9,
      ...filters
    })
  );
};

WizardDuck.setProjectById = (projectId = "") => dispatch =>
  new Promise(res => {
    WizardService.getProjectById(projectId).then(data => {
      res(data);
      dispatch({
        type: actionTypes.replaceProject,
        payload: data
      });
    });
  });

WizardDuck.setDetail = (projectId = "", userId = "", preventLogErr = false) => (dispatch, getState) => {
  const projectStates = EnumsDuck.getProjectStates(getState());
  dispatch(WizardDuck.setLoading("detail", true));

  return new Promise((res, rej) => {
    WizardService.getProjectById(projectId, preventLogErr)
      .then(project => {
        let additionalData = {};

        if (g(project, "author", []).find(author => author.id === userId)) {
          Promise.all([
            g(projectStates, "inProgress.name", "") === project.state
              ? WizardService.getProjectChecklist(projectId)
              : Promise.resolve([]),
            // WizardService.getProjectChecklist(projectId),
            WizardService.getProjectHandshakes(projectId)
          ])
            .then(promisedData => {
              additionalData = {
                ...additionalData,
                handshakesBySubroleId: (promisedData[1] || []).reduce((res, hs) => {
                  const ids = res[hs.subrole.id] || [];
                  return {
                    ...res,
                    [hs.subrole.id]: [...ids, hs]
                  };
                }, {}),
                handshakesByIds: (promisedData[1] || []).reduce(
                  (res, hs) => ({
                    ...res,
                    [hs.id]: hs
                  }),
                  {}
                ),
                projectChecklist: promisedData[0] || []
              };
              dispatch({
                type: actionTypes.setDetail,
                payload: {
                  ...project,
                  ...additionalData
                }
              });
              res({
                ...project,
                ...additionalData
              });
              dispatch(WizardDuck.setLoading("detail", false));
            })
            .catch(err => {
              dispatch({
                type: actionTypes.setDetail,
                payload: {
                  ...project,
                  ...additionalData
                }
              });
              res({
                ...project,
                ...additionalData
              });
              rej(err);
              dispatch(WizardDuck.setLoading("detail", false));
            });
          // if (project.state === g(projectStates, 'inProgress.name', '')) {
          //     WizardService.getProjectChecklist(projectId).then(checklist => {
          //         console.log('getProjectChecklist', checklist)
          //     });
          // }
        } else {
          WizardService.getProjectChecklist(projectId).then(projectChecklist => {
            dispatch({
              type: actionTypes.setDetail,
              payload: {
                ...project,
                handshakesBySubroleId: {},
                projectChecklist
              }
            });
            res({
              ...project,
              handshakesBySubroleId: {}
            });
            dispatch(WizardDuck.setLoading("detail", false));
          });
        }
      })
      .catch(err => {
        rej(err);
      });
  });
};

WizardDuck.toggleVisibility = (project = {}) => dispatch =>
  new Promise(res => {
    if (project.isVisible) {
      WizardService.hideProject(project.id).then(updatedProject => {
        dispatch({
          type: actionTypes.setDetail,
          payload: updatedProject
        });
        res(updatedProject);
      });
    } else {
      WizardService.unhideProject(project.id).then(updatedProject => {
        dispatch({
          type: actionTypes.setDetail,
          payload: updatedProject
        });
        res(updatedProject);
      });
    }
  });

WizardDuck.updateChecklistItem = (projectId = "", itemId = "", handshakeId = "") => dispatch =>
  new Promise(res => {
    WizardService.updateProjectChecklistItem(projectId, itemId, handshakeId).then(data => {
      console.log("updateProjectChecklistItem", data);
      dispatch({
        type: actionTypes.updateDetail,
        payload: {
          projectChecklist: data
        }
      });
      res(data);
    });
  });

WizardDuck.deleteChecklistItem = (projectId = "", itemId = "", handshakeId = "") => dispatch =>
  new Promise(res => {
    WizardService.deleteProjectChecklistItem(projectId, itemId, handshakeId).then(data => {
      console.log("deleteProjectChecklistItem", data);
      dispatch({
        type: actionTypes.updateDetail,
        payload: {
          projectChecklist: data
        }
      });
      res(data);
    });
  });

WizardDuck.uploadManuscript = (...args) => dispatch => {
  WizardService.uploadManuscript(...args).then(data => {
    dispatch({
      type: actionTypes.updateDetail,
      payload: data
    });
    window.alertify.success("Rukopis úspěšně nahrán!");
  });
};

WizardDuck.deleteManuscript = (projectId = "") => dispatch => {
  WizardService.deleteManuscript(projectId).then(() => {
    dispatch({
      type: actionTypes.updateDetail,
      payload: {
        manuscript: null,
        manuscriptUrl: ""
      }
    });
    window.alertify.success("Rukopis úspěšně smazán!");
  });
};

WizardDuck.followProject = (userId, projectId, isDetail = false) => (dispatch, getState) => {
  WizardService.followProject(projectId).then(project => {
    dispatch({
      type: actionTypes.replaceProjectFromProjects,
      payload: project,
      meta: { projectState: EnumsDuck.getStateByLabel(getState(), project.state) }
    });
    dispatch({
      type: actionTypes.followProject,
      payload: project
    });
    if (isDetail) {
      dispatch({
        type: actionTypes.setDetail,
        payload: project
      });
    }
    dispatch(UserDuck.loadUserFromToken());
    dispatch(WizardDuck.fetchFollowedProjects(userId));
  });
};

WizardDuck.unfollowProject = (userId, projectId, isDetail = false) => (dispatch, getState) => {
  WizardService.unfollowProject(projectId).then(project => {
    dispatch({
      type: actionTypes.unfollowProject,
      payload: project
    });
    dispatch({
      type: actionTypes.replaceProjectFromProjects,
      payload: project,
      meta: { projectState: EnumsDuck.getStateByLabel(getState(), project.state) }
    });
    if (isDetail) {
      dispatch({
        type: actionTypes.setDetail,
        payload: project
      });
    }
    dispatch(WizardDuck.fetchFollowedProjects(userId));
    dispatch(UserDuck.loadUserFromToken());
  });
};

WizardDuck.fetchFollowedProjects = (userId, params = {}) => (dispatch, getState) => {
  if (isEmpty(params)) {
    const followedProjects = getOwnState(getState()).followedProjects;
    params = {
      order: followedProjects.order,
      page: followedProjects.page,
      limit: followedProjects.limit
    };
  }
  dispatch(WizardDuck.setLoading("followedProjects", true));
  dispatch({
    type: actionTypes.addToFollowedProjects,
    payload: params
  });

  WizardService.getFollowedProjects(userId, params).then(data => {
    const projects = g(data, "data", []);
    const projectIds = projects.map(p => p.id);
    return WizardService.getAllProjectChecklists(projectIds).then(checklists => {
      const newFollowedProjects = projects.map(project => ({
        ...project,
        ...{ projectChecklist: checklists[project.id] }
      }));
      dispatch({
        type: actionTypes.setFollowedProjects,
        payload: { ...data, data: newFollowedProjects }
      });

      dispatch(WizardDuck.setLoading("followedProjects", false));
    });
  });
};

WizardDuck.setInterestedProjects = (genres = []) => dispatch => {
  dispatch(WizardDuck.setLoading("interestedProjects", true));
  WizardService.fetchProjects({
    limit: 3,
    page: 1,
    genres
  }).then(data => {
    const projects = g(data, "data", []);
    const projectIds = projects.map(p => p.id);
    return WizardService.getAllProjectChecklists(projectIds).then(checklists => {
      const newFollowedProjects = projects.map(project => ({
        ...project,
        ...{ projectChecklist: checklists[project.id] }
      }));
      dispatch({
        type: actionTypes.setInterestedProjects,
        payload: { ...data, data: newFollowedProjects }
      });
      dispatch(WizardDuck.setLoading("interestedProjects", false));
    });
  });
};

WizardDuck.createProjectNews = (projectId = "", data = {}) => dispatch => {
  WizardService.addProjectNews(projectId, data).then(news => {
    // dispatch({
    //     type: actionTypes.addNews,
    //     payload: news
    // });
    dispatch(WizardDuck.setDetailNews(projectId));
  });
};

WizardDuck.editProjectNews = (projectId = "", newsId = "", data = {}) => dispatch => {
  delete data.id;
  delete data.updatedAt;
  delete data.createdAt;
  return new Promise((res, rej) => {
    WizardService.editProjectNews(projectId, newsId, data)
      .then(news => {
        dispatch({
          type: actionTypes.addNews,
          payload: news
        });
        res(news);
      })
      .catch(err => rej(err));
  });
};

WizardDuck.removeNews = (projectId = "", newsId = "") => dispatch => {
  WizardService.deleteProjectNews(projectId, newsId).then(() => {
    dispatch(WizardDuck.setDetailNews(projectId));
    // dispatch({
    //     type: actionTypes.removeNews,
    //     payload: newsId
    // });
  });
};

WizardDuck.setDetailNews = (projectId, opt = initialState.detailNews) => dispatch => {
  WizardService.getProjectNews(projectId, opt).then(data => {
    dispatch({
      type: actionTypes.setDetailNews,
      payload: data
    });
  });
};

WizardDuck.setDetailRewards = (order_inx, opt = initialState.detailRewards) => dispatch => {
  WizardService.getProjectRewards(order_inx, opt).then(rewards => {
    dispatch({
      type: actionTypes.setDetailRewards,
      payload: rewards
    });
  });
};

WizardDuck.setIntroAnnotation = (annotation = "") => ({
  type: actionTypes.setIntroAnnotation,
  payload: annotation
});

const getOwnState = state => g(state, WizardDuck.name, initialState);
WizardDuck.getStep = state => getOwnState(state).step;
WizardDuck.canGoBack = state => getOwnState(state).canGoBack;
WizardDuck.getMaxStep = state => getOwnState(state).maxStep;
WizardDuck.getGenres = state => getOwnState(state).genres;
WizardDuck.getTags = state => getOwnState(state).tags;
WizardDuck.getTitle = state => getOwnState(state).title;
WizardDuck.getAnnotation = state => getOwnState(state).annotation;
WizardDuck.getFiles = state => getOwnState(state).files;
WizardDuck.getEditor = state => getOwnState(state).editor;
WizardDuck.getProjects = state => g(getOwnState(state), "projects.data", {});
WizardDuck.getProjectsGroups = state => g(getOwnState(state), "projectsGroups", {});
WizardDuck.getProject = (state, id) => WizardDuck.getProjects(state)[id] || {};
WizardDuck.getUserProjects = state => g(getOwnState(state), "userProjects.data", {});
WizardDuck.isEditing = state => !!getOwnState(state).editing;

WizardDuck.canLoadMoreProjects = state => !!g(getOwnState(state), "projects.links.next", false);
WizardDuck.canLoadLessProjects = state => !!g(getOwnState(state), "projects.links.prev", false);
WizardDuck.getProjectsPagesCount = state =>
  Math.ceil(g(getOwnState(state), "projects.total", 0) / g(getOwnState(state), "projects.limit", 1));

WizardDuck.isPublicProfile = (state, projectId) => isEmpty(g(WizardDuck.getUserProjects(state), projectId, {}));
WizardDuck.isDashboardLoading = state => {
  const loadingState = g(getOwnState(state), "loading", {});
  return (
    loadingState.projects ||
    loadingState.userProjects ||
    loadingState.followedProjects ||
    loadingState.interestedProjects
  );
};

WizardDuck.getDetail = state => g(getOwnState(state), "detail", {});
WizardDuck.isLoadingDetail = state => g(getOwnState(state), "loading.detail", g(initialState, "loading.detail", false));

WizardDuck.isLoadingFollowedProjects = state =>
  g(getOwnState(state), "loading.followedProjects", g(initialState, "loading.followedProjects", false));
WizardDuck.getFollowedProjects = state => g(getOwnState(state), "followedProjects.data", []);
WizardDuck.canLoadMoreFollowedProjects = state => !!g(getOwnState(state), "followedProjects.links.next", false);
WizardDuck.canLoadLessFollowedProjects = state => !!g(getOwnState(state), "followedProjects.links.prev", false);
WizardDuck.getFollowedProjectTotalCount = state =>
  Math.ceil(g(getOwnState(state), "followedProjects.total", 0) / g(getOwnState(state), "followedProjects.limit", 1));

WizardDuck.isLoadingInterestedProjects = state =>
  g(getOwnState(state), "loading.interestedProjects", g(initialState, "loading.interestedProjects", false));
WizardDuck.isLoadingUserProjects = state =>
  g(getOwnState(state), "loading.userProjects", g(initialState, "loading.userProjects", false));
WizardDuck.getInterestedProjects = state => g(getOwnState(state), "interestedProjects.data", {});

WizardDuck.isLoadingSamples = state =>
  g(getOwnState(state), "loading.userProjects", g(initialState, "loading.userProjects", false));

WizardDuck.getNews = state => Object.values(g(getOwnState(state), "detailNews.data", {}));
WizardDuck.getRewards = state => Object.values(g(getOwnState(state), "detailRewards.rewards", []));
WizardDuck.getIntroImage = state => Object.values(g(getOwnState(state), "detailRewards.files", []));
WizardDuck.getIntroAnnotation = state => g(getOwnState(state), "detailRewards.annotation", "");
WizardDuck.getIntroVideo = state => g(getOwnState(state), "detailRewards.video", "");
WizardDuck.canLoadMoreNews = state => !!g(getOwnState(state), "detailNews.links.next", false);
WizardDuck.canLoadLessNews = state => !!g(getOwnState(state), "detailNews.links.prev", false);
WizardDuck.getNewsTotalCount = state =>
  Math.ceil(g(getOwnState(state), "detailNews.total", 0) / g(getOwnState(state), "detailNews.limit", 1));

export default WizardDuck;
