import React, { Component } from "react";
import PropTypes from "prop-types";
import { get as g } from "lodash";

import "./crowdfundingSelffinancingTabs.sass";
import { be, bm } from "../../../utils/bliss";
import Text from "../../text/text";

const MODULE_NAME = "CrowdfundingSelffinancingTabs";

class CrowdfundingSelffinancingTabs extends Component {
  constructor(props) {
    super(props);

    const { tabs, defaultTabHash } = props;
    const selectedTabIndex =
      Array.isArray(tabs) && tabs.findIndex(t => t.hash_anchor === defaultTabHash.replace(/#/g, ""));

    this.state = {
      tab: Math.max(selectedTabIndex, 0),
      id: Math.random()
    };
  }

  onTabChange = tab => {
    const { onSelect, tabs } = this.props;
    this.setState({
      tab
    });
    if (onSelect) {
      onSelect(tabs[tab]);
    }
  };

  render() {
    const { tabs } = this.props;
    const { tab, id } = this.state;
    const activeText = g(tabs, `${tab}.text`, null);

    return (
      <div className={bm(MODULE_NAME)}>
        <div key={`tab-${id}`} className={be(MODULE_NAME, "headerContainer")}>
          {tabs.map((t, idx) => (
            <div
              key={idx}
              className={be(MODULE_NAME, "header", tab === idx ? "active" : "")}
              onClick={() => this.onTabChange(idx)}
            >
              {t.title}
            </div>
          ))}
        </div>
        <Text greenReward semi bold>
          <div className={be(MODULE_NAME, "body")} dangerouslySetInnerHTML={{__html: activeText}} />
        </Text>
      </div>
    );
  }
}

CrowdfundingSelffinancingTabs.defaultProps = {
  defaultTabHash: "",
  tabs: []
};

CrowdfundingSelffinancingTabs.propTypes = {
  tabs: PropTypes.array,
  defaultTabHash: PropTypes.string
};

export default CrowdfundingSelffinancingTabs;
